import React, {  useState } from 'react';
import { Button, Flex, Page } from '../../../../ui';
import TaskModule from '../TaskModule';
import { useDispatch } from 'react-redux';
import { completeQuiz } from '../../../quizes/quizesSlice';
import { saveDataForObject } from "../../../global/globalSlice";
import { Pagination } from '@mui/material';

const QuizQuestions = ({ setQuizEnded, quizData, pageInfo, themeColor, startTime }) => {
  const dispatch = useDispatch();
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const currentTask = pageInfo?.pageRows[currentTaskIndex];
  const totalTasks = pageInfo?.pageRows?.length || 0;
  const [sessionResults, setSessionResults] = useState([]);

  const initializeTaskTracking = (pageRows) =>
    pageRows?.reduce((acc, page) => {
      page.pageModules.forEach((module) => {
        const taskId = module.data?.params?.task?.taskId;
        if (taskId) acc[taskId] = false;
      });
      return acc;
    }, {});

  const [taskTracking, setTaskTracking] = useState(() => initializeTaskTracking(pageInfo.pageRows));

  /** determine quiz success/failure status **/
  const calculateQuizStatus = (score, threshold) => {
    // const totalQuestions = Object.keys(answers).length;
    const passThreshold = (Number(threshold) / 100) || 0.8; /** Default to 80% if no threshold provided **/
    return score >= totalTasks * passThreshold ? 'QUIZ_SUCCESS' : 'QUIZ_FAIL';
  };

  /** update page data with quiz results **/
  const getPageData = (pageInfo, score, status, startTime, endTime, duration) => {
    const existingPageData = pageInfo?.userdata?.pages?.[pageInfo.id] || [];

    return {
      ...pageInfo.userdata,
      locked: true, //TODO: ??? do a proper investigation - i think it signals that quiz is finished (Mariana)
      pages: {
        ...pageInfo.userdata.pages,
        [pageInfo.id]: [
          ...existingPageData,
          {
            score,
            total: totalTasks,
            status,
            startTime,
            endTime,
            duration,
          },
        ],
      },
    };
  };

  const handleEndQuiz = () => {
    dispatch(completeQuiz({ pageId: pageInfo.id }))
      .then(res => {
        const { score } = res.payload;
        const endTime = new Date();
        const duration = endTime - startTime;
        const status = calculateQuizStatus(score, quizData.threshold);

        dispatch(saveDataForObject({
          objectId: pageInfo.id,
          objectType: 'page',
          data: getPageData(pageInfo, score, status, startTime, endTime, duration),
        })).then((res) => {
          const results = res.meta.arg.data.pages[pageInfo.id]
          setQuizEnded(results[results.length - 1])
        }
        
        );
      });
  };

  return (
    <Page gap='30'>
      <Flex width='100%' justify='center' align='flex-start'>
        {currentTask.pageModules.map(module =>
          <TaskModule 
            isQuiz={true} key={module.id}
            data={module.data} module={module} themeColor={themeColor}
            sessionResults={sessionResults} setSessionResults={setSessionResults}
            taskTracking={taskTracking} setTaskTracking={setTaskTracking}
            currentTaskIndex={currentTaskIndex} setCurrentTaskIndex={setCurrentTaskIndex}
            totalTasks={totalTasks}
          />
        )}
      </Flex>
      <Button 
        $variant='customized' 
        $bgcolor={themeColor} 
        onClick={handleEndQuiz} 
        disabled={!Object.values(taskTracking).every(value => value)}>End Quiz</Button>
      <Pagination 
        onChange={(event, page) => setCurrentTaskIndex(page-1)}
        count={totalTasks} 
        size="small" 
        page={currentTaskIndex+1}
      />
    </Page>
  )
};

export default QuizQuestions;
