import React from 'react';
import styled from 'styled-components'
import Flex from '../Flex';

const Container = styled.div`
  position: relative;
  background: ${({$themeColor}) => $themeColor};
  padding: 15px;
  border: 1px solid #ccc;
  margin-top: 20px;
  border-radius: 8px;
  text-align: center;
  width:100%;

  &::before, &::after {
    content: '';
    position: absolute;
    border: 11px solid transparent;
    bottom: 100%;
    left: 19px;
    border-bottom-color: #ccc;
  }

  &::after {
    border: 10px solid transparent;
    left: 20px;
    border-bottom-color: ${({$themeColor}) => $themeColor};
  }

`

const SpeechBubble = ({ children, themeColor }) => {
  return (
    <Container $themeColor={themeColor}>
      <Flex direction='column' gap='20' align='flex-start'>{children}</Flex>
    </Container>
  )
}

export default SpeechBubble;
