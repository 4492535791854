import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { theme, Typo } from '../../../../ui';
import { selectCourse, selectCourseToc } from '../../../course/courseSlice';
import { useNewViewCourseId, useNewViewPageId } from '../../../../hooks/readFromUrls';
import isEmpty from 'lodash.isempty';
import TocMenuSection from './TocMenuSection';
import { getLighterThemeColor } from '../../../../ui/theme/colors';

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
`;

const HeadWrapper = styled.div`
  background: ${({ $ultralightColor }) => $ultralightColor || theme.colors.NEUTRAL_10};
  margin: 0;
  max-width: 600px;
`;

const CourseName = styled.div`
  min-height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    padding: 5px 0 5px 15px;
  }
`;


const TocMenuContent = () => {

  const course = useSelector(selectCourse);
  const toc = useSelector(selectCourseToc);
  const activePageId = useNewViewPageId();
  const courseId = useNewViewCourseId();

  // Memoize 'active' state to avoid unnecessary re-renders
  const active = useMemo(() => {
    if (!isEmpty(toc) && activePageId !== undefined) {
      const sectionIndex = toc.findIndex((section) =>
        section.pages.findIndex((page) => page.id.toString() === activePageId) !== -1
      );
      if (sectionIndex !== -1) {
        const newActive = Array(toc.length).fill(false);
        newActive[sectionIndex] = true;
        return newActive;
      }
    }
    return [];
  }, [toc, activePageId]);

  const ultralightColor = getLighterThemeColor(course?.theme?.color, 0.95);

  return (
    <>
      {course && (
        <HeadWrapper $ultralightColor={ultralightColor}>
          {course.coverImage?.url && <StyledImage src={course.coverImage?.url} alt="Course" />}
          <CourseName>
            <Typo.MediumText fontWeight="600" $textAlign="left">
              {course.name}
            </Typo.MediumText>
            <Typo.SmallText fontWeight="300" $textAlign="left">
              By {course.ownerName}
            </Typo.SmallText>
          </CourseName>
        </HeadWrapper>
      )}

      <div>
        {toc?.map((section, i) =>
          <TocMenuSection
            course={course}
            themeColor={course?.theme?.color}
            section={section}
            activePageId={activePageId}
            active={!!active[i]}
            key={i}
            courseId={courseId}
          />
        )}
      </div>
    </>
  );
};

export default TocMenuContent;
