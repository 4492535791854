import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { Modal, Typo } from '../../../ui';
import { fetchEditableCourses, selectCourses } from '../../courses/coursesSlice';
import { addUserToCourse, selectUsers } from '../../users/usersSlice';
import SearchSelect from '../../../ui/SearchSelect';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  padding: 1rem 0;
`

const InviteTeacher = ({ isModalOpen, closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const courses = useSelector(selectCourses)
  const users = useSelector(selectUsers)

  const [contentValid, setContentValid] = useState(false);
  const [course, setCourse] = useState(null)
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (isModalOpen) {
      dispatch(fetchEditableCourses({}))
    }
  }, [dispatch, isModalOpen])

  useEffect(() => {
    let isValid = true
    // if (!course)
    //   isValid = !!selectedCourses.length && selectedUsers.length;
    // else if (select === 'courses') isValid = !!selectedCourses.length
    // else isValid = selectedUsers.length

    setContentValid(isValid);
  }, [user, course])


  const clearState = useCallback(() => {
    setCourse(null)
    setUser(null)
    setContentValid(false)
  }, [])

  const closeDialog = useCallback(() => {
    closeModal()
    clearState();
  }, [clearState, closeModal])

  const addTeacherToCourse = useCallback(() => {
    // can only invite one user to one course
    const courseId = course?.id
    const userId = user.id
    const role = 'builder'

    dispatch(addUserToCourse({ userId, courseId, role }))
    closeDialog()
  }, [dispatch, closeDialog, course, user])

  return (
    <Modal
      title={'Add a teacher to a course'}
      onClose={closeDialog}
      onAccept={addTeacherToCourse}
      showActions
      open={isModalOpen}
      acceptLabel={t('globals.send')}
      acceptIsDisabled={!contentValid}
    >
      <>
        <Wrapper>
          <Typo.SmallText>Select a teacher from the list</Typo.SmallText>
          <SearchSelect
            setValue={setUser}
            selectedOptions={user}
            id='users'
            options={users}
            // label={t('invites.addUsers')}
            renderOption={(props, option) => {
              return <li {...props} key={option.id || option}>
                <div >
                  <Typo.Text >{option.name}</Typo.Text>
                  <Typo.Info >{option.email}</Typo.Info>
                </div>
              </li>
            }}
          />

        </Wrapper>
        <Wrapper>
          <Typo.Text>Teacher will have forever access to selected course</Typo.Text>
          <SearchSelect
            setValue={setCourse}
            selectedOptions={course}
            id='courses'
            label={t('invites.courseInstruction')}
            options={courses.map((item) => ({ name: `${item.name} (${item.id})`, id: item.id }))}
          />
        </Wrapper>
      </>
    </Modal>
  );
}

export default InviteTeacher;
