import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { courseApi, coursePackageApi } from '../../utils/urls';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED } from '../../utils/constants';
import { fetchStore } from '../webshop/globalSlice'
import { createCourse, deleteCourse, duplicateCourse } from '../course/courseSlice';
import { removeGroupFromCourse } from '../groups/groupsSlice';
import dayjs from 'dayjs';
import httpClient from '../../services/httpClient';

// ----------------- Thunks -----------------------------

export const fetchEditableCourses = createAsyncThunk('courses/fetchEditableCourses', async (props, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      searchText: props.searchText,
      showUncorrected: props.showUncorrected
    },
    method: 'findBuildable'
  };

  const res = await httpClient.post(courseApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
})

export const fetchCoursesByGroupId = createAsyncThunk('courses/fetchCoursesByGroupId', async ({ circleId, sortBy, sortMode }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      circleId,
      sortMode,
      sortBy
    },
    method: 'findByCircleId'
  };

  const res = await httpClient.post(courseApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

export const fetchEditablePackages = createAsyncThunk('courses/fetchCoursePackages', async (_, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {},
    method: 'read'
  };

  const res = await httpClient.post(coursePackageApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

// ----------------- Reducers ---------------------------

const coursesAdapter = createEntityAdapter()

const initialState = {
  status: 'idle',
  ids: [],
  entities: {},
  group: {
    ids: [],
    entities: {}
  }
}

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    reset: () => initialState

  },
  extraReducers: builder => {
    builder
      .addCase(fetchStore.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        coursesAdapter.setAll(state, action.payload.items)
      })
      .addCase(fetchEditableCourses.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchEditableCourses.rejected, (state, action) => {
        state.status = STATUS_FAILED
      })
      .addCase(fetchEditableCourses.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        coursesAdapter.setAll(state, action.payload)
      })
      .addCase(fetchCoursesByGroupId.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchCoursesByGroupId.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        coursesAdapter.setAll(state.group, action.payload)
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        coursesAdapter.removeOne(state, action.meta.arg)
      })
      .addCase(removeGroupFromCourse.fulfilled, (state, action) => {
        coursesAdapter.removeOne(state.group, action.meta.arg.courseId)
      })
      .addCase(duplicateCourse.fulfilled, (state, action) => {
        coursesAdapter.setOne(state, action.payload)
      })
      .addCase(fetchEditablePackages.fulfilled, (state, action) => {
        state.packages = action.payload
      })
      .addCase(createCourse.fulfilled, (state, action) => {
        const todayDate = dayjs()
        const newCourse = {
          name: action.payload.name,
          id: action.payload.id,
          created: todayDate.toISOString().split('T')[0],
          ucpPeriod: action.payload.ucpPeriod,
          studentCount: 0,
        }
        coursesAdapter.setOne(state, newCourse)
      })
  }
})

export const { reset } = coursesSlice.actions

export default coursesSlice.reducer

// ----------------- Selectors --------------------------
export const {
  selectAll: selectCourses,
  selectById: selectCourseById,
} = coursesAdapter.getSelectors((state) => state.courses)

export const {
  selectAll: selectGroupCourses,
  selectById: selectGroupCourseById,
} = coursesAdapter.getSelectors((state) => state.courses.group)

export const selectAllCourseIds = (state) => state.courses.ids;
export const selectStatus = state => state.courses.status;
export const selectPackages = state => state.courses.packages;
