import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { fetchStoreCurrency, updateCurrency } from '../settings/settingsSlice'
import httpClient from '../../services/httpClient';
import { contentApi } from '../../utils/urls';

// ----------------- Thunks -----------------------------

export const saveDataForObject = createAsyncThunk('content/saveDataForObject', async ({ objectId, objectType, data }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      data,
      objectId,
      objectType
    },
    method: 'saveDataForObject'
  };

  const res = await httpClient.post(contentApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
})

// ----------------- Reducers -----------------------------

const initialState = {
  menuSliderToggle: false,
  menuTocToggle: false,
  notesSliderToggle: false,
  incarnateModal: false,
  loginModal: false,
  params: null,
  country: {},
  currency: 'SEK'
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIncarnateModal(state, action) {
      state.incarnateModal = action.payload
    },
    setLoginModal(state, action) {
      state.loginModal = action.payload
    },
    setMenuSliderToggle(state, action) {
      state.menuSliderToggle = action.payload
    },
    setNotesSliderToggle(state, action) {
      state.notesSliderToggle = action.payload
    },
    setMenuTocToggle(state, action) {
      state.menuTocToggle = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStoreCurrency.fulfilled, (state, action) => {
        state.currency = action.payload.currency
        state.storeId = action.payload.storeId
      })
      .addCase(updateCurrency.fulfilled, (state, action) => {
        state.currency = action.payload.currency
      })
  }
})

export const { setIncarnateModal, setLoginModal, setMenuSliderToggle, setNotesSliderToggle, setMenuTocToggle } = globalSlice.actions

export default globalSlice.reducer

// ----------------- Selectors -----------------------------

export const selectIncarnateModal = state => state.global.incarnateModal;
export const selectLoginModal = state => state.global.loginModal;
export const selectMenuSliderToggle = state => state.global.menuSliderToggle
export const selectMenuTocToggle = state => state.global.menuTocToggle
export const selectNotesSliderToggle = state => state.global.notesSliderToggle
export const selectCurrency = state => state.global.currency;
export const selectStoreId = state => state.global.storeId;
