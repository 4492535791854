import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { nanoid } from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';

import { storeApi } from '../../utils/urls';
import { fetchStore } from '../webshop/globalSlice';
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------

export const saveLayout = createAsyncThunk('home/saveLayout', async ({ storeName, layoutData }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      name: storeName,
      data: layoutData
    },
    method: 'saveLayout'
  };

  const res = await httpClient.post(storeApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Alterations saved with success'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  dispatch(fetchStore({ store: storeName }));

  return res.data;
})

// ----------------- Reducers -----------------------------

const initialState = {
  hasLayout: false,
  coverMedia: {video: {id: null, url: null}, image: {id: null, url: null}}, //video:{id:'', url:''}, image: { id: '', url: ''}}, //may need to rethink this part
  ctaBanner:{}, //title:'', descrition:''
  introduction: {}, //title: '', text: '' },
  suggestedCourse: null,
  infoIcons: [], //{iconName:'', title:'', text:'', id:''}],
  highlightedCourses: [],
  infoPoints: [], //{title:'', text:'', id:''}],
  status: 'idle',
  testimonies: [],//{image:'', name:'', text:''}]
  customFile: {}, // {id: '', url: '', name: ''}
  sectionTitles: {},
  showCategories:false,
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    addImageToLayout(state, action) {
      state.coverMedia.image = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(saveLayout.pending, (state, action) => {
        state.status = STATUS_LOADING
      })
      .addCase(saveLayout.rejected, (state, action) => {
        state.status = STATUS_FAILED
      })
      .addCase(saveLayout.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED
      })
      .addCase(fetchStore.rejected, (state, action) => {
        state.status = STATUS_FAILED
      })
      .addCase(fetchStore.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED

        const layoutProps = ['coverMedia', 'introduction','suggestedCourse', 'infoIcons', 'highlightedCourses', 'infoPoints', 'testimonies' ]

        let hasLayout =  action.payload.layoutStructure && !!Object.keys(action.payload.layoutStructure).length
        if(hasLayout)
          hasLayout = Object.keys(action.payload.layoutStructure)
                        .map((objectProp)=> layoutProps.includes(objectProp))
                        .filter(Boolean)

        state.hasLayout = !!hasLayout?.length

        if (!state.hasLayout) {
          state.coverMedia = initialState.coverMedia;
          state.introduction = initialState.introduction;
          state.suggestedCourse = initialState.suggestedCourse;
          state.infoIcons = initialState.infoIcons
          state.highlightedCourses = initialState.highlightedCourses;
          state.infoPoints = initialState.infoPoints;
          state.testimonies = initialState.testimonies;
          state.customFile = initialState.customFile;
          state.sectionTitles = initialState.sectionTitles;
          state.showCategories = initialState.showCategories;
        } else {
          const mockId = (data) => data?.map((el, i) => { return { ...el, id: nanoid() } });

          const {
            coverMedia,
            ctaBanner,
            introduction,
            suggestedCourse,
            infoIcons,
            highlightedCourses,
            infoPoints,
            testimonies,
            customFile,
            sectionTitles,
            showCategories
          } = action.payload.layoutStructure
          state.coverMedia = coverMedia || {};
          state.ctaBanner = ctaBanner;

          state.introduction = introduction;
          state.suggestedCourse = suggestedCourse;
          state.infoIcons = infoIcons && mockId(infoIcons);
          state.highlightedCourses = highlightedCourses;
          state.infoPoints = infoPoints && mockId(infoPoints);
          state.testimonies = testimonies;
          state.customFile = customFile;
          state.sectionTitles = sectionTitles ? sectionTitles : initialState.sectionTitles;
          state.showCategories = showCategories;
        }

      })
  }
});

export const { addImageToLayout } = homeSlice.actions

export default homeSlice.reducer

// ----------------- Selectors -----------------------------

export const selectCoverMedia = state => state.webshop.home.coverMedia
export const selectCtaBanner = state => state.webshop.home.ctaBanner
export const selectIntroduction = state => state.webshop.home.introduction
export const selectSuggestedCourse = state => state.webshop.home.suggestedCourse
export const selectInfoIcons = state => state.webshop.home.infoIcons
export const selectHighlightedCourses = state => state.webshop.home.highlightedCourses
export const selectInfoPoints = state => state.webshop.home.infoPoints
export const selectLayout = state => state.webshop.home
export const selectHasLayout = state => state.webshop.home.hasLayout
export const selectStatus = state => state.webshop.global.status
export const selectShowCategories = state => state.webshop.home.showCategories
