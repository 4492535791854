import { Tooltip } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Flex, Icon, Typo, theme } from '../../../ui';
import { useDrop } from 'react-dnd'
// import { useDispatch } from 'react-redux';
// import { deleteDirectory } from '../mediaSlice';

const Content = styled.div`
  cursor: pointer;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:240px;
  border-radius:14px;
  
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div > i { margin-right: 0.5rem; }
`;

export const ItemTypes = {
  All: 'all',
}

const DropableFolder = ({ data, openFolder }) => {
  // const dispatch = useDispatch();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.All,
    drop: () => (data),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  const isActive = canDrop && isOver

  let backgroundColor = theme.colors.NEUTRAL_LIGHT
  if (isActive) {
    backgroundColor = theme.colors.NEUTRAL_20;
  } else if (canDrop) {
    // backgroundColor = 'darkkhaki'
  }

  // const deleteFolder = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   console.log('DELETE')
  //   dispatch(deleteDirectory(data.id))
  // }

  return (
    <Tooltip title={data.name} >
      <Content ref={drop} style={{ backgroundColor }} onClick={() => openFolder(data)} >
        <Flex justify='space-between' width='100%'>
          <Flex>
            <Icon.Folder size='small' color={theme.colors.NEUTRAL_60} />
            <Typo.SmallText>{data.name}</Typo.SmallText>
          </Flex>
        </Flex>
      </Content>
    </Tooltip>
  )
}

export default DropableFolder;
