import React from 'react';
import { IconButton, Badge, Tooltip, CircularProgress } from '@mui/material';
import { Icon } from '..';
import styled from 'styled-components'
import theme from '../theme';
import { useTranslation } from 'react-i18next';
import { media } from '../../utils/media';

const sizes = { 'x-small':'26px', 'small': '32px', 'standard': '41px', 'xxl': '70px' }

const IconButtonStyled = styled(IconButton)`
  max-height: ${({ $size }) => sizes[$size] || sizes.standard};
  width: ${({ $size }) => sizes[$size] || sizes.standard};
  height: ${({ $size }) => sizes[$size] || sizes.standard};
  background-color: ${({ $bgColor }) => $bgColor} !important;
  display: ${({ $onlyMobile }) => $onlyMobile && 'none'} !important;
  ${media.lessThan('tablet')} {
    display:${({ $onlyMobile }) => $onlyMobile && 'inherit'} !important;
  }
`
const BadgeStyled = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${({ badgecolor }) => badgecolor};
    color: ${(props) => props.theme.colors.NEUTRAL_0};
  }
`;

const IconAsButton = ({ 
  hasBadge, 
  badgeInfo, 
  iconName, 
  color, 
  clickHandle, 
  className, 
  disabled, 
  size, 
  tooltipLabel, 
  placement, 
  loading,
  onlyMobile,
  bgColor }) => {
  const { t } = useTranslation();

  const badgeIcon = (Component, props) => {
    const { badgeInfo, hasBadge, color } = props
    return hasBadge ?
      <BadgeStyled badgeContent={badgeInfo} badgecolor={theme.colors.ACTION} >
        <Component color={color} />
      </BadgeStyled>
      : <Component color={color} size={size} />
  }

  /** Material UI's Tooltip component doesn't work properly when its child has a disabled prop
   * We need to wrap the disabled buttons in a container, which doesn't have disabled prop. **/
  return (
    <Tooltip title={tooltipLabel || t(`icons.${iconName}`)} placement={placement || 'bottom'}>
      <span>
        <IconButtonStyled 
          aria-label={iconName} 
          onClick={clickHandle} 
          className={className} 
          disabled={disabled} 
          $size={size}
          $onlyMobile={onlyMobile}
          $bgColor={bgColor}
        >
          {loading ? (
            <CircularProgress
              size={18}
              sx={{
                color: theme.colors.NEUTRAL_40,
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-9px',
                marginLeft: '-9px',
              }}
            />
          ) : badgeIcon(Icon[iconName], { badgeInfo, iconName, hasBadge, color, size })}
        </IconButtonStyled>
      </span>
    </Tooltip >
  );
};

IconAsButton.displayName = 'IconAsButton'

export default IconAsButton;
