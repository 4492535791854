import React, { useEffect, useRef, useState } from 'react';
import { IconAsButton, Textinput, Typo, theme } from '../../../../ui';
import styled from 'styled-components';
import { media } from '../../../../utils/media';
import { useDispatch, useSelector } from 'react-redux';
import { selectPage } from '../CoursePage/coursePageSlice';
import { InputAdornment } from '@mui/material';
// import { useTranslation } from 'react-i18next';
import { saveNotes } from '../../../notes/notesSlice';

const Container = styled.div`
  width:100%;
  margin:0;
  padding:2rem 0 0;
  display:flex;
  flex-direction: column;
  justify-content: space-between;

  height: calc(100vh - ${theme.headerSize});

  ${media.lessThan('bigTablet')} {
    border-radius: 15px;
    background-color: white;
    height: ${({$notesHeight}) => `calc(${$notesHeight}px + 200px)` };
    min-height: 250px;
  }
`
  
const NotesList = styled.ul`
  overflow: scroll;
  margin: 0 1rem 1rem 0;
  height: ${({$notesHeight}) => `calc(${$notesHeight}px)` };
`

const InputContainer = styled.div`
  padding:0 1rem 0.5rem;
  text-align: right;

  .MuiInputAdornment-root {
    align-items: flex-end;
    height: 96px;
    max-height: 96px;
  }
`

const NotesContent = () => {
  const dispatch = useDispatch()
  // const { t } = useTranslation();
  
  const [notesForPage, setNotesForPage] = useState([]);
  const [pageModuleId, setPageModuleId] = useState([]);
  const [notesHeight, setNotesHeight] = useState(0)

  const page = useSelector(selectPage);

  const ref = useRef(null)
  const notesRef = useRef(null)

  useEffect(()=>{
    if(page?.pageRows?.length){
      let notes = []
      setPageModuleId(page.pageRows[0].pageModules[0].id)
      page.pageRows.forEach((row)=>{
        row.pageModules.forEach((module)=>{

          module.note.text && notes.push(module.note) 
        })
      })
      setNotesForPage(notes)
    }
  },[page]);

  useEffect(() => {
    const notesContent = ref?.current?.getBoundingClientRect().height;
    setNotesHeight(notesContent <= 400 ? notesContent : 400)
  }, [ref?.current?.getBoundingClientRect()]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = ()=>{
    dispatch(saveNotes({pageModuleId, text:notesRef.current.value}))
  }

  return (
    <Container $notesHeight={notesHeight} >
      <div ref={ref}>
        <Typo.ContentBigHeader $marginBottom='20px'>Your notes</Typo.ContentBigHeader>
        {!!notesForPage?.length && <NotesList $notesHeight={notesHeight} >
          {notesForPage?.map((note)=> {
            return <li><Typo.Text>{note.text}</Typo.Text></li>
          })}
        </NotesList>}
      </div>
      <InputContainer >
      
        <Textinput
          key={`take-a-note`}
          inputRef={notesRef}
          label={'write a note'}
          isMultiline
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconAsButton size='x-small' iconName='Send' clickHandle={onSave} />
              </InputAdornment>
            ),
          }}
        />
      </InputContainer>

     
        
    </Container>
  );
}

export default NotesContent;
