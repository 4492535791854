import React, { useEffect, useState } from 'react';
import { SearchSelect } from '../../ui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAllCategories } from './categoriesSlice';

const SelectCategories = ({ setCategory, preselectedCategories, isDisabled }) => {
  const { t } = useTranslation();

  const options = useSelector(selectAllCategories);
  const [localCats, setLocalCats] = useState([])


  useEffect(()=>{
    setLocalCats(preselectedCategories)
  }, [preselectedCategories, options])

  const addCategory = (values)=>{
    setLocalCats(values)
    setCategory(values)
  }

  return (
    options.length && <SearchSelect
      multiple
      setValue={addCategory}
      selectedOptions={localCats}
      id='course-categories'
      options={options}
      isOptionEqualToValue={(option, value) => value && option.id === value.id}
      label={t('course.categoriesLabel')}
      disabled={isDisabled}
      limitTags={isDisabled ? undefined : 2}
      translationLabel='categories.labels'
      optionLabel='slug'
    />
  );
};

export default SelectCategories;
