import { ToggleButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Typo } from '../../../../ui';
import cloneDeep from 'lodash.clonedeep';

const CourseListFilter = ({ options, handleFilter, selected }) => {
  const { t } = useTranslation();

  const [sortedCats, setSortedCats] = useState([])

  useEffect(()=>{
    const newCats = cloneDeep(options)
    newCats.sort(function (a, b) {
      if (t(`categories.labels.${a.slug}`) < t(`categories.labels.${b.slug}`)) {
        return -1;
      }
      if (t(`categories.labels.${a.slug}`) > t(`categories.labels.${b.slug}`)) {
        return 1;
      }
      return 0;
    });

    setSortedCats(newCats)

  },[options, t])

  const handleClick = (filter) => {
    const index = selected.indexOf(filter);
    let newArray = [...selected]
    if (index > -1) { 
      newArray.splice(index, 1); 
    } else newArray.push(filter)
    
    handleFilter(newArray)
  }

  return (
    <Flex gap='15' wrap='wrap'>
      {sortedCats?.map(filter => <ToggleButton key={filter.slug} value={filter.name}
        selected={selected?.includes(filter.slug)}
        onChange={()=> handleClick(filter.slug)}>
        <Typo.SmallText>{t(`categories.labels.${filter.slug}`)}</Typo.SmallText>
      </ToggleButton>
      )}
    </Flex>
  )
}


export default CourseListFilter;
