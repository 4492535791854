import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { mrWolfUcpApi, mrWolfUserApi } from '../../utils/urls';
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------

export const getCourseAccessLevel = createAsyncThunk('api/getCourseAccessLevel', async ({ courseId, userId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { courseId, userId, type: 1 },
    method: 'list'
  };

  const res = await httpClient.post(mrWolfUcpApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});
export const updateCourseAccessLevel = createAsyncThunk('api/updateCourseAccessLevel', async ({ acessLevelId, role, userId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { id: acessLevelId, mode: role },
    method: 'update'
  };

  const res = await httpClient.post(mrWolfUcpApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

export const incarnateSuggest = createAsyncThunk('api/mr.wolf-user', async ({ params }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { token: params },
    method: 'suggest'
  };

  const res = await httpClient.post(mrWolfUserApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

// ----------------- Reducers -----------------------------

const initialState = {
  userSuggest: [],
}

const mrWolfSlice = createSlice({
  name: 'mrWolf',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // ------ SUGGEST ----
      .addCase(incarnateSuggest.fulfilled, (state, action) => {
        state.userSuggest = action.payload;
      })
  }
})

export default mrWolfSlice.reducer

// ----------------- Selectors -----------------------------

export const selectUserSuggest = state => {
  return state?.mrWolf?.userSuggest;
}
