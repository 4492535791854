import React from 'react';
import EmbedModule from '../EmbedModule';
import { Figure } from '../../../../ui';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom:20px;
`

const TaskHeaderMedia = ({data}) => {

  //audio dont seem to be working in old code

  return (
  <Container>
    { data.type === 'video' ? 
      <video style={{ width: '100%' }} controls alt={data.name}>
        <source
          src={data.url.includes('.mp4') ? data.url : data.url + '.mp4'}
          type={data.type}
        />
      </video>
      : data.type === 'image' ?  <Figure.Rectangle><img alt={'header material'} src={data.url} /></Figure.Rectangle>
      : data.type === 'audio' ?
        <audio controls style={{ width: '100%' }}>
          <source
            src={data.url}
            type={data.type}
          />
        </audio>
      : data.type === 'external' ?
        <EmbedModule params={data} />
    : <div> Show Media </div>
    }
  </Container>
  ) 
};

export default TaskHeaderMedia;
