import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { incarnate } from '../../features/auth/authSlice';
import { selectUserSuggest, incarnateSuggest } from '../../features/mrWolf/mrWolfSlice';
import { selectIncarnateModal, setIncarnateModal } from '../../features/global/globalSlice';
import { useSelector } from 'react-redux';
import { Modal, Textinput, theme } from '../../ui';
import styled from 'styled-components';
import debounce from 'lodash.debounce';

const SuggestContainer = styled.div`
  position: absolute;
  border: 1px solid ${theme.colors.NEUTRAL_40};
  background-color: ${theme.colors.NEUTRAL_0};
  max-height: 250px;
  overflow-y: scroll;
`

const SuggestBlock = styled.div`
  padding: 3px 5px;
  cursor: pointer;
  line-height: 100%;
`

const SuggestDescription = styled.div`
  font-size: ${theme.fontSizes.xSmall}
`

const IncarnateModal = () => {
  const dispatch = useDispatch();
  const incRef = useRef(null);
  const incarnationOpen = useSelector(selectIncarnateModal);
  const userSuggest = useSelector(selectUserSuggest);
  const [isSuggest, setSuggest] = useState(false);

  const handleIncarnation = useCallback(() => {
    const captionToEqual = (user) => Number(incRef.current.value) ? user.id : user.caption;
    const valueToEqual = Number(incRef.current.value) ? Number(incRef.current.value) : incRef.current.value
    const neededUser = userSuggest.find(user => captionToEqual(user) === valueToEqual);

    dispatch(incarnate({ userId: neededUser?.id }));
    dispatch(setIncarnateModal(false))
  }, [dispatch, userSuggest]);

  const closeIncarnationModal = useCallback(() => {
    dispatch(setIncarnateModal(false))
  }, [dispatch]);

  const handleSuggest = useCallback(debounce(() => {
    const { current: { value } } = incRef;

    if (value.length < 3 && isNaN(parseInt(value, 10))) {
      if (isSuggest) setSuggest(false);
      return null;
    }
    setSuggest(true);
    dispatch(incarnateSuggest({ params: value }))
  }, 1000), [dispatch, isSuggest]);

  const pickSuggestion = useCallback((user) => {
    incRef.current.value = user.caption;
    setSuggest(false);
  }, [])

  return (
    <Modal
      title={'Incarnate - Enter user email or user ID'}
      onClose={closeIncarnationModal}
      onAccept={handleIncarnation}
      showActions
      open={incarnationOpen}
      acceptLabel={'Incarnate'}
      overflow={'visible'}
    >
      <Textinput
        autoFocus
        margin="dense"
        id="incarnate"
        type="text"
        fullWidth
        inputRef={incRef}
        onChange={handleSuggest}
      />
      {isSuggest && <SuggestContainer>
        {userSuggest.map(user => {
          return <SuggestBlock
            key={user.id}
            onClick={() => pickSuggestion(user)}
          >
            <div>{user.caption}</div>
            <SuggestDescription>
              {`${user.descr[0]}, ${user.descr[1]}, ${user.descr[2]}`}
            </SuggestDescription>
          </SuggestBlock>
        })}
      </SuggestContainer>}
    </Modal>
  )
};

export default IncarnateModal;
