import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Textinput,  Flex, theme } from '../../../../ui';
import FileUploader from '../../../media/uploaders/FileUploader';
import EditableAttach from '../../../tasks/components/EditableAttach';
import { getLighterThemeColor } from '../../../../ui/theme/colors';

const FilesWrapper = styled.div`
  div {
    margin-top: 0.5rem;
  }
`;

const TextType = ({data, taskRef, onSubmit, themeColor}) => {
  const { t } = useTranslation();
  const [answerFiles, setAnswerFiles] = useState([]);

  const deleteAnswerFile = useCallback((file) => {
    const result = answerFiles.filter(el => el.id !== file.id);
    setAnswerFiles(result);
  }, [answerFiles])

  const submitTaskAnswer = () => {
    let finalAnswer = taskRef.current[data.taskId].value;

    if (answerFiles.length > 0) {
      answerFiles.forEach((file) => {
        finalAnswer += `[file:${file.id}]`;
      })
    }
    !!finalAnswer.length && onSubmit([finalAnswer]);
    setAnswerFiles([])
    taskRef.current[data.taskId].value = '';
  }

  return <div>
    <Textinput
      placeholder={t('globals.answer')}
      isMultiline
      inputRef={ref => taskRef.current[data.taskId] = ref}
    />
    <Flex gap='10' justify='space-between' >
      <FileUploader
        id={'taskAddFile'}
        saveAction={(callbackFile) => setAnswerFiles(prevFiles => [...prevFiles, callbackFile])}
        label={t('globals.attachFile')}
        bgcolor={getLighterThemeColor(themeColor)}
        noMediaLibrary
      />
      <Button $variant='customized' $bgcolor={themeColor || theme.colors.PRIMARY} onClick={submitTaskAnswer}>{t('globals.submit')}</Button>
    </Flex>
    <FilesWrapper>
      <EditableAttach files={answerFiles} handleDelete={deleteAnswerFile}/>
    </FilesWrapper>
  </div>
};

export default TextType;
