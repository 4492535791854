import React, { useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { Textinput, Button, Flex, Typo } from '../../../ui';
import Loading from '../../../ui/Loading';
import { STATUS_LOADING, TOAST_ERROR } from '../../../utils/constants';
import { logIn, selectAuthStatus } from '../authSlice';
import SupportLink from './SupportLink';
import { loadGoogleScript } from '../../../App';
import { media } from '../../../utils/media';
import { enqueueSnackbar } from 'notistack';

const Wrapper = styled.div`
  display:flex;
  flex-direction:column;
  gap:40px;
  min-width:420px;
  min-height:400px;
  justify-content:center;
  ${media.lessThan('tablet')} {
    min-width:260px;
  }
`
const ButtonStyled = styled(Button)`
  align-self:flex-end; 
`

const SocialButtons = styled.div`
  display:flex;
  gap:30px;
  ${media.lessThan('tablet')} {
    flex-direction:column;
  }

`

const Login = ({ onForgotPass, onLogginCallBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const emailRef = useRef(null);
  const passRef = useRef(null);
  const LoginStatus = useSelector(selectAuthStatus);

  useEffect(() => {
    /* global google */
    loadGoogleScript()
      .then(() => {
        google.accounts.id.renderButton(
          document.getElementById('GoogleLoginBtn'),
          {
            theme: 'filled_black',
            text: 'signin',
            shape: 'circle',
          }
        )
      }).catch(console.error)

  }, [LoginStatus])

  const handleLogIn = useCallback((event) => {

    const emailValue = emailRef.current.value
    const passValue = passRef.current.value

    if (!emailValue || !passValue) return enqueueSnackbar('Missing email and/or password.', { variant: TOAST_ERROR })

    dispatch(logIn({ identity: emailValue, credential: passValue })).then(() => {
      onLogginCallBack && onLogginCallBack()
    })
  }, [dispatch, onLogginCallBack]);

  return (
    <Wrapper>
      {LoginStatus === STATUS_LOADING ? <Loading /> : <>
        <Flex direction='column' width='100%' align='flex-start'>
          <Textinput
            autoFocus
            margin='dense'
            id='email'
            label={t('globals.email')}
            type='email'
            fullWidth
            inputRef={emailRef}
          />
          <Textinput
            margin='dense'
            id='pass'
            label={t('globals.password')}
            type='password'
            fullWidth
            inputRef={passRef}
          />
          <Button $asText onClick={onForgotPass}>{t('auth.forgotPassword')}</Button>
        </Flex>

        <Flex direction='column' gap='50'>
          <ButtonStyled
            onClick={handleLogIn}
          >{t('auth.signIn')}</ButtonStyled>
          <Flex direction='column' gap='20'>
            <Typo.Info>{t('auth.orSignInWith')}</Typo.Info>
            <SocialButtons>
              <div id={'GoogleLoginBtn'} />
              {/* <Button $variant='primary' icon='Facebook' onClick={() => console.log('facebook')}>{t('auth.facebook')}</Button> */}
            </SocialButtons>
          </Flex>
          <SupportLink />
        </Flex>
      </>
      }
    </Wrapper>
  );
};

export default Login;
