import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Header, Avatar } from '../../ui';
import theme from '../../ui/theme';
import {
  setMenuSliderToggle,
  setLoginModal,
  setMenuTocToggle,
} from './globalSlice';
import {
  selectStoreName,
  selectUserTheme,
  selectOwnerName,
} from '../webshop/globalSlice';
import { selectCartItemsCount } from '../webshop/cartSlice';
import { selectAuthState } from '../auth/authSlice';
import { useModule } from '../../hooks/readFromUrls';
import { MODULES_LIST } from '../../utils/constants';
import { selectCourse } from '../course/courseSlice';

// Menu button
const ProfileAvatar = styled.div`
  z-index: 1315;
  position: fixed;
  left: 14px;
  top: 10px;
  cursor: pointer;
  margin-right: 0.5rem;
`;

const HeaderContainer = ({ noHeader }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const storeName = useSelector(selectStoreName)
  const userTheme = useSelector(selectUserTheme)
  const cartItemsCount = useSelector(selectCartItemsCount)
  const authState = useSelector(selectAuthState)
  const ownerName = useSelector(selectOwnerName)
  const course = useSelector(selectCourse)

  const module = useModule();
  const { userInfo, loggedIn } = authState;

  const openLoginModal = useCallback(() => {
    dispatch(setLoginModal(true));
  }, [dispatch]);

  const openCart = useCallback(() => {
    navigate(`/store/${storeName}/checkout`);
  }, [storeName, navigate]);

  const goToHome = useCallback(() => {
    navigate(`/store/${storeName}`);
  }, [navigate, storeName]);

  const handleOpenUserMenu = useCallback(() => {
    dispatch(setMenuSliderToggle(true));
  }, [dispatch]);

  const handleOpenTocMenu = useCallback(() => {
    dispatch(setMenuTocToggle(true));
  }, [dispatch]);

  const title = useMemo(() => {
    if (module === 'store' || module === 'store_edit') return [];
    const title = MODULES_LIST[module]?.label(t);
    return [title];
  }, [module, t]);

  const headerProps = {
    title,
    headerLogo: userTheme?.logoUrl,
    cartItems: cartItemsCount,
    goToHome,
    goToLink: navigate,
    openCart,
    inStore: module === 'store',
    inCourseView: module === 'view',
    courseThemeColor: module === 'view' ? course?.theme?.color : null,
    ownerName,
    openTocMenu: handleOpenTocMenu,
    loggedIn,
    openLoginModal,
    userInfo: userInfo?.user,
    bgColor: module && ['course'].includes(module) && theme.colors.COURSIO,
  };

  return (
    <>
      <ProfileAvatar onClick={handleOpenUserMenu}>
        {userInfo && (
          <Avatar
            url={userInfo?.user?.avatarUrl}
            name={userInfo?.user?.displayName}
            size='small'
          />
        )}
      </ProfileAvatar>
      {!noHeader && <Header {...headerProps} />}
    </>
  );
};

export default HeaderContainer;
