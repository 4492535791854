import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useNoHeaderModules } from '../../hooks/noHeaderModules'
import { CONTENT_STANDARD, CONTENT_FULL, media } from '../../utils/media'

const PageWrapper = styled.div`
  height: ${({ $position, $substractBottom }) => `calc(100vh - ${$position}px - ${$substractBottom ? $substractBottom :0}px)`};
  background-color: ${(props) => props.$background};
  overflow: hidden;
  width: 100%;
`

const Header = styled.div`
  margin: auto;
  max-width: ${({ $size }) => $size !== CONTENT_FULL ? `${$size}px` : $size};
  padding: 2rem 1rem 0.5rem;
  ${media.lessThan('tablet')} {
    padding: 1.5rem 1rem 1rem;
  }
`

const Scrollable = styled.div`
  overflow-y: scroll;
  height: 100%;
`

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 1rem;
  max-width: ${({ $size }) => $size !== CONTENT_FULL ? `${$size}px` : $size};
  gap: ${(props) => props.$gap || 0}px;
  align-items: ${({ $align }) => $align};
  height:${(props) => props.$fullheight && '100%'};
`

const Page = ({
  children,
  staticElements,
  align,
  gap,
  size,
  backgroundColor,
  fullheight,
  substractBottom,
}) => {

  const [height, setHeight] = useState(0)
  const [position, setPosition] = useState(0)

  const ref = useRef(null)
  const ref2 = useRef(null)
  const noHeader = useNoHeaderModules();

  useEffect(() => {
    setPosition(ref2?.current?.getBoundingClientRect().y)
    setHeight(ref?.current?.clientHeight)
  }, [ref?.current?.clientHeight, ref2?.current?.getBoundingClientRect()]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      { staticElements && <Header $size={size || CONTENT_STANDARD} ref={ref}>
        {staticElements}
      </Header>}
      <PageWrapper $substractBottom={substractBottom} ref={ref2} $position={position} $background={backgroundColor} $hasStaticElements={staticElements} $elemHeight={height} $noHeader={noHeader}>
        <Scrollable>
          <PageContent $fullheight={fullheight} $align={align || 'center'} $gap={gap} $size={size || CONTENT_STANDARD} $noHeader={noHeader}>
            {children}
          </PageContent>
        </Scrollable>
      </PageWrapper>
    </>
  );

};

export default Page;
