import React, { useEffect } from 'react';
import { Typo, SearchSelect } from '../../ui';
import { useTranslation } from 'react-i18next';

const UcpPeriodSelector = ({ options, duration, setDuration }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!duration?.label) setDuration({ label: 'oneYear', id: 12, time: 31536000 })
  }, [duration, setDuration])

  return (
    duration && <>
      <Typo.Text>{t('course.ucpPeriodLabel')}</Typo.Text>
      <SearchSelect
        multiple={false}
        setValue={setDuration}
        selectedOptions={duration}
        id='time-period'
        options={options}
        isOptionEqualToValue={(option, value) => value && option.time === value.time}
        label={t('course.ucpPeriodTitle')}
        optionLabel='label'
        translationLabel='course'
      />
    </>
  );
}

export default UcpPeriodSelector;
