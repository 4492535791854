import React, {useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import { Page, Loading, Flex } from '../../../../ui';
import { fetchPageById, markPageAsViewed, selectPage, selectPageStatus } from './coursePageSlice';
import { STATUS_SUCCEEDED, coursePageNavigation } from '../../../../utils/constants';
import { selectCourseTheme } from '../../../course/courseSlice';
import ModuleSwitcher from './ModuleSwitcher';
import { useNewViewCourseId } from '../../../../hooks/readFromUrls';
import Quiz from '../Quiz';
import { CONTENT_SMALL, media } from '../../../../utils/media';
import { getUserThemeColor } from '../../../../ui/theme/colors';

const RowModules = styled(Flex)` 
  &:first-of-type{
    margin-top:30px;
  }
  ${media.lessThan('laptop')} {
    flex-direction:${({$isNotesOpen})=> $isNotesOpen && 'column'};
  }
  ${media.lessThan('bigTablet')} {
    flex-direction:column;
  }

  //hide empty components so we dont have extra empty space
  &:empty{
    display:none;
  }
`

const CoursePage = ({pageId, isQuiz, quizData, isNotesOpen}) => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(selectPage);
  const pageStatus = useSelector(selectPageStatus);
  const courseTheme = useSelector(selectCourseTheme);
  const courseId = useNewViewCourseId();
  const themeColor = useMemo(()=> getUserThemeColor(courseTheme?.color), [courseTheme]);

  useEffect(() => {
    if (pageId?.length && pageId !== 'diploma'){
      dispatch(fetchPageById({pageId})).then(() => {
        dispatch(markPageAsViewed({pageId, courseId}))
      });
    }
    
  }, [pageId, dispatch, courseId]);

  const renderPageContent = () => {
    return (
      <Page substractBottom={coursePageNavigation} gap="40" size={CONTENT_SMALL}>
        {pageInfo.pageRows.map(pageRow =>
          <RowModules $isNotesOpen={isNotesOpen} gap="20" width="100%" key={pageRow.id} justify="center" align="flex-start">
            {pageRow.pageModules.map(module => <ModuleSwitcher columns={pageRow.pageModules.length} key={module.id} module={module} themeColor={themeColor} />)}
          </RowModules>
        )}
      </Page>
    );
  };

  const renderPageDiploma = () => {
    return (
      <Page substractBottom={coursePageNavigation} gap="40" size={CONTENT_SMALL}>
        <RowModules $isNotesOpen={isNotesOpen} gap="20" width="100%" justify="center" align="normal">
          <ModuleSwitcher columns={1} key={'diploma'} module={{data:{}, type:{name:'diploma'}}} themeColor={themeColor} />
        </RowModules>
      </Page>
    );
  };

  return pageId === 'diploma' ? renderPageDiploma() : pageStatus === STATUS_SUCCEEDED && !isEmpty(pageInfo) ? (
    isQuiz ? <Quiz quizData={quizData} pageInfo={pageInfo} themeColor={themeColor} /> 
      : renderPageContent()
  ) : <Loading themeColor={themeColor}/>
};

export default CoursePage;
