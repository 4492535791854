import { enqueueSnackbar } from 'notistack';
import { authStatus, selectAuthToken } from '../features/auth/authSlice';
import { TOAST_ERROR } from '../utils/constants';

const httpClient = {
  post: (url, body, { getState, dispatch, rejectWithValue, noRedirect = false, responseType = 'json', noToken = false }) => {
    const authToken = selectAuthToken(getState())
    if (!url) {
      return enqueueSnackbar('url is required to call httpClient.post', { variant: TOAST_ERROR });
    }
    if(!noToken && !authToken ) {
      console.log(body.method, 'NO VALID TOKEN', url)
      // enqueueSnackbar('token in not valid', { variant: TOAST_ERROR });
    } 
    else {
      const init = {
        method: 'POST',
        headers: { token:authToken },
        body: body instanceof FormData ? body : JSON.stringify(body),
      };
      return fetch(url, init).then(async res => {
        // console.log('FETCH', res.status, noRedirect)
        if (!res.ok) {
          if (res.status === 500) {
            !noRedirect && enqueueSnackbar('Something is not working you can try again or contact support', { variant: TOAST_ERROR })
            // !noRedirect ? enqueueSnackbar('Something is not working you can try again or contact support', { variant: TOAST_ERROR })
            //   : window.location.pathname = '/coursio-error';
            return { data: rejectWithValue({ errorCode: res.status, response: await res.json() }) }
          } else if (res.status < 200 || res.status >= 300) {
            if (res.status === 401) {
              //no access - probably logged out or not correct role
              dispatch(authStatus());
            }
            return { data: rejectWithValue({ errorCode: res.status, response: await res.json() }) }
          } else {
            console.error(res);
            throw Error('Could not fetch the data from: ' + url);
          }
        }
        if (responseType === 'json') {
          return res.json().then(json => {
            if (json.reason && json.time) {
              throw Error(json.reason);
            }
            return json;
          });
        } else {
          return res;
        }
      });
    }
  }
};

export default httpClient;
