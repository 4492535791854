import { createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'
  import { categoryApi } from '../../utils/urls';
 
  import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED } from '../../utils/constants';
  import httpClient from '../../services/httpClient';
  
  // ----------------- Thunks -----------------------------
  
  export const fetchAllCategories = createAsyncThunk('categories/fetchAllCategories', async (_, { getState, dispatch, rejectWithValue }) => {
  
    const body = {
      data: {},
      method: 'read'
    };
  
    const res = await httpClient.post(categoryApi(), body, getState, dispatch, rejectWithValue);
    return res.data;
  });

  export const fetchStoreCategories = createAsyncThunk('categories/fetchStoreCategories', async (store, { getState, dispatch, rejectWithValue }) => {
  
    const body = {
      data: {name:store},
      method: 'listCategoryByUsername'
    };
  
    const res = await httpClient.post(categoryApi(), body, getState, dispatch, rejectWithValue);
    return res.data;
  });
  
  // ----------------- Reducers ---------------------------

  const categoriesAdapter = createEntityAdapter()

  const initialState = {
    status: 'idle',
    ids: [],
    entities: {},
    inuse:{
      ids: [],
      entities: {},
    }
  };
  
  const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(fetchAllCategories.pending, (state, action) => {
          state.status = STATUS_LOADING;
        })
        .addCase(fetchAllCategories.rejected, (state, action) => {
          state.status = STATUS_FAILED;
        })
        .addCase(fetchAllCategories.fulfilled, (state, action) => {
          state.status = STATUS_SUCCEEDED;
          categoriesAdapter.setAll(state, action.payload)
        })
        .addCase(fetchStoreCategories.pending, (state, action) => {
          state.status = STATUS_LOADING;
        })
        .addCase(fetchStoreCategories.rejected, (state, action) => {
          state.status = STATUS_FAILED;
        })
        .addCase(fetchStoreCategories.fulfilled, (state, action) => {
          state.status = STATUS_SUCCEEDED;
          categoriesAdapter.setAll(state.inuse, action.payload)
        })
        
    }
  })
  
//   export const {  } = categoriesSlice.actions;
  
  export default categoriesSlice.reducer
  
  // ----------------- Selectors --------------------------

  export const {
    selectAll: selectAllCategories,
    selectById: selectCategoryById,
  } = categoriesAdapter.getSelectors((state) => state.categories)

  export const {
    selectAll: selectCategoriesInUse,
    selectById: selectCategoryInUseById,
  } = categoriesAdapter.getSelectors((state) => state.categories.inuse)
  
  