import React from 'react';
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Drawer } from '@mui/material';
import {
  setMenuSliderToggle,
  selectMenuSliderToggle,
  selectMenuTocToggle,
  setMenuTocToggle
} from '../../features/global/globalSlice';
import AppMenu from './AppMenu';
import TocMenuContent from '../../features/viewCourse/components/TocMenu/TocMenuContent';


const MenuWrapper = styled.div`
  display:${(props) => props.$visible ? 'inherit' : 'none'}
`

const MenuSlider = () => {
  const dispatch = useDispatch();

  const menuSliderToggle = useSelector(selectMenuSliderToggle);
  const menuTocToggle = useSelector(selectMenuTocToggle);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(setMenuSliderToggle(open))
    dispatch(setMenuTocToggle(open))
  };


  return (
    <div>
      <Drawer
        anchor={'left'}
        open={menuSliderToggle || menuTocToggle}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 300 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <MenuWrapper $visible={menuSliderToggle}><AppMenu /></MenuWrapper>
          <MenuWrapper $visible={menuTocToggle}><TocMenuContent /></MenuWrapper>
        </Box>
      </Drawer>
    </div>
  );
}

export default MenuSlider;
