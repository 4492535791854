import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme, Typo, Icon } from '../../../../ui';
import { List, ListItem, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useNewViewCourseId } from '../../../../hooks/readFromUrls';
import { getDarkerThemeColor, getLighterThemeColor, getUserThemeColor } from '../../../../ui/theme/colors';

const AccordionStyled = styled(Accordion)`
  margin: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: none !important;
  border: none !important;
  background-color: ${({ $active, $lightColor }) => $active ? $lightColor || theme.colors.NEUTRAL_LIGHT : theme.colors.NEUTRAL_0} !important;
  &:before {
    background-color: transparent !important;
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    background: transparent !important;
    &.Mui-expanded {
      margin: 16px 0 0 !important;
    }
  }

  .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 0px !important;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
  p {
    width: 100%;
  }
`;

const ListStyled = styled(List)`
  padding: 0 0 12px !important;
`;

const ListItemStyledLocked = styled(ListItem)`
  pointer-events: none; /* Prevent interaction */
`;

const ListItemStyledActive = styled(ListItem)`
  padding-left: 30px;
  position: relative;
  background-color: ${({ $lightColor }) => $lightColor || theme.colors.PRIMARY_LIGHT} !important;

  &:before {
    content: '';
    border-left: 6px solid ${({ $darkColor }) => $darkColor || theme.colors.COURSIO_NEW};
    position: absolute;
    width: 6px;
    height: 100%;
    left: 0;
  }
`;

const ListItemStyled = styled(ListItem)`
  padding-left: 30px;
  position: relative;
`;

const SectionDefault = styled.div`
  min-width: 18px;
  height: 18px;
  margin-right: 10px;
  border-radius: 50%;
  background: ${({ $darkColor }) => $darkColor || theme.colors.COURSIO_NEW};
`;

const SectionViewed = styled(SectionDefault)`
  background: linear-gradient(0deg, ${({ $darkColor }) => $darkColor || theme.colors.COURSIO_NEW} 50%, ${({ $lightColor }) => $lightColor || theme.colors.COURSIO_NEW_LIGHT} 50%);
`;

const SectionReady = styled(SectionDefault)`
  position: relative;
  background-color: ${({ $darkColor }) => $darkColor || theme.colors.COURSIO_NEW};

  &::before {
    width: 6px;
    height: 3px;
    content: '';
    border-bottom: 2px solid ${({$detailsColor})=> $detailsColor || theme.colors.COURSIO_NEW};
    border-left: 2px solid ${({$detailsColor})=> $detailsColor || theme.colors.COURSIO_NEW};
    position: absolute;
    left: 29%;
    top: 33%;
    transform: rotate(-50deg);
  }
`;

const PageViewed = styled.div`
  min-width: 18px;
  height: 18px;
  position: relative;
  margin-right: 10px;

  &::before {
    width: 6px;
    height: 3px;
    content: '';
    border-bottom: 2px solid ${({$darkColor})=> $darkColor || theme.colors.COURSIO_NEW};
    border-left: 2px solid ${({$darkColor})=> $darkColor || theme.colors.COURSIO_NEW};
    position: absolute;
    left: 30%;
    top: 35%;
    transform: rotate(-50deg);
  }
`;

const PageUnseen = styled.div`
  width: 31px;
`;

const PageBloked = styled.div`
  width: 12px;
`;

const TocMenuSection = ({ section, activePageId, active, themeColor, course }) => {
  const navigate = useNavigate();
  const courseId = useNewViewCourseId();

  const [expanded, setExpanded] = useState(true);
  const darkColor = getDarkerThemeColor(themeColor);
  const userColor = getUserThemeColor(themeColor);
  const lightColor = getLighterThemeColor(themeColor);
  const ultralightColor = getLighterThemeColor(themeColor, 0.95);

  const handleChange = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const navigateToPage = useCallback(
    (pageId, isLocked) => {
      if (!isLocked) {
        navigate(`/view/course/${courseId}/${pageId}`);
      }
    },
    [navigate, courseId]
  );

  const updatedCourse = useMemo(() => {
    if (!course || !course.toc) return course;

    const settings = course.settings || {};
    const dripContent = settings.dripContent || {};

    if (dripContent.gatedAccess) {
      let prevPageUnlocked = true;

      return {
        ...course,
        toc: course.toc.map((section) => ({
          ...section,
          pages: section.pages.map((page) => {
            if(page.id === 'diploma') return { ...page, isLocked: false };
            else if (prevPageUnlocked) {
              const isPageUnlocked =
                !page.isLocked && (page.isViewed || page.progress > 0 || Number(activePageId) === page.id);
              prevPageUnlocked = isPageUnlocked;
              return { ...page, isLocked: false };
            }
            return { ...page, isLocked: true };
          }),
        })),
      };
    }
    return course;
  }, [course, activePageId]);

  return (
    <AccordionStyled
      key={section.id}
      expanded={expanded}
      onChange={handleChange}
      $active={active}
      $lightColor={ultralightColor}
    >
      <AccordionSummary expandIcon={<Icon.ExpandMore />}>
        {section.progress === 1 ? (
          <SectionReady $darkColor={darkColor} />
        ) : section.progress > 0 ? (
          <SectionViewed $lightColor={userColor} $darkColor={darkColor} />
        ) : (
          <SectionDefault $darkColor={darkColor} />
        )}
        <Typo.Text>{section.name}</Typo.Text>
      </AccordionSummary>
      <AccordionDetails>
        <ListStyled>
          {updatedCourse?.toc
            ?.find((sec) => sec.id === section.id)
            ?.pages.map((page) => {
              const Component = page.isLocked ? ListItemStyledLocked
                : page.id?.toString() === activePageId ? ListItemStyledActive 
                  : ListItemStyled;

              return (
                <Component
                  key={page.id}
                  button
                  onClick={() => navigateToPage(page.id, page.isLocked)}
                  $lightColor={lightColor}
                  $darkColor={darkColor}
                >
                  {page.isLocked ? <><Icon.Lock size='x-small' color={darkColor} /><PageBloked /></>
                   : page.progress === 1 ? <PageViewed $darkColor={darkColor} /> : <PageUnseen />}
                  <Typo.MediumText 
                    color={page.isLocked ? theme.colors.NEUTRAL_60 : theme.colors.NEUTRAL_PRIMARY}
                  >{page.name}</Typo.MediumText>
                </Component>
              );
            })}
        </ListStyled>
      </AccordionDetails>
    </AccordionStyled>
  );
};

export default TocMenuSection;
