
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_SUCCEEDED, STATUS_LOADING, API_STATUS_ALL_TRANSACTIONS, TOAST_SUCCESS, TOAST_ERROR } from '../../utils/constants';

import { transactionsApi } from '../../utils/urls';
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------

export const fetchTransactions = createAsyncThunk('transactions/fetchTransactions', async (_, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      sortBy: 'date',
      sortMode: 'desc',
      status: API_STATUS_ALL_TRANSACTIONS
    },
    method: 'read'
  };

  const res = await httpClient.post(transactionsApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;

})

export const resendReceipt = createAsyncThunk('transactions/resendReceipt', async (id, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      id
    },
    method: 'resendReceipt'
  };

  const res = await httpClient.post(transactionsApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;

})

// ----------------- Reducers -----------------------------

const transactionsAdapter = createEntityAdapter()

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: {
    status: 'idle',
    ids: [],
    entities: {}
  },
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.status = STATUS_LOADING
      })
      .addCase(fetchTransactions.rejected, (state) => {
        state.status = STATUS_FAILED
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        transactionsAdapter.setAll(state, action.payload)
      })
      .addCase(resendReceipt.rejected, (state, action) => {
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(resendReceipt.fulfilled, (state, action) => {
        enqueueSnackbar('Request to send receipt was successfull', { variant: TOAST_SUCCESS })
      })
  }
})

// export const { } = transactionsSlice.actions

export default transactionsSlice.reducer

// ----------------- Selectors -----------------------------


export const {
  selectAll: selectTransactions,
  selectById: selectTransactionsById,
} = transactionsAdapter.getSelectors((state) => state.transactions)
