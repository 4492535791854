import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { xroomApi } from '../../utils/urls';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED } from '../../utils/constants';
import httpClient from '../../services/httpClient';

// ----------------- Thunks -----------------------------

export const xroomInit = createAsyncThunk('webinar/xroomInit', async ({ id, isWebinar, isHost, addHostKey, useCoursioKeys }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      id,
      isWebinar,
      isHost,
      addHostKey,
      useCoursioKeys,
    },
    method: 'init'
  };

  const res = await httpClient.post(xroomApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});


// ----------------- Reducers ---------------------------
const initialState = {
  status: 'idle',
  data: {},
};

const webinarSlice = createSlice({
  name: 'webinar',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(xroomInit.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(xroomInit.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(xroomInit.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = action.payload;
      })
  }
})

export default webinarSlice.reducer

// ----------------- Selectors --------------------------

export const selectStatus = state => state.webinar.status;
export const selectWebinarData = state => state.webinar.data;
