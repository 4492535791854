import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Flex, theme, IconAsButton } from '../../ui';
import { fetchCourseById, selectCourse } from '../course/courseSlice';
import { useNewViewCourseId, useNewViewPageId } from '../../hooks/readFromUrls';
import { coursePageNavigation, drawerWidth } from '../../utils/constants';
import { media } from '../../utils/media';
import TocMenu from './components/TocMenu';
import CoursePage from './components/CoursePage';
import { getDarkerThemeColor, getLighterThemeColor, getUserThemeColor } from '../../ui/theme/colors';
import Buttons from '../../ui/Buttons';
import NotesDrawer from './components/NotesDrawer/Index';
import { selectNotesSliderToggle, setNotesSliderToggle } from '../global/globalSlice';
import NotesMobile from './components/NotesDrawer/NotesMobile';
import usePageNavigation from '../../utils/pageNavigation';

const IconAsButtonStyled = styled(IconAsButton)`
  position: absolute !important;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  z-index: 999;
`;

const NavButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  height: ${coursePageNavigation}px;
  justify-content: space-between;
  background-color: ${({ $backgroundColor }) => $backgroundColor} !important;
  width: calc(100% - ${drawerWidth}px);

  ${media.greaterThan('bigTablet')} {
    width: calc(100% - ${(props) => (props.$compactSize ? drawerWidth * 2 : drawerWidth)}px);
  }

  ${media.lessThan('tablet')} {
    width: 100%;
  }
`;

const LockedSectionMessage = styled.div`
  padding: 20px;
  text-align: center;
  color: ${theme.colors.NEUTRAL_60};
`;

const ContentWrapper = styled(Flex)`
  margin-left: ${drawerWidth}px;
  position: relative;
  height: calc(100vh - ${theme.headerSize});
  margin-right: 0;

  ${media.greaterThan('bigTablet')} {
    margin-right: ${({ $extraMargin }) => ($extraMargin ? drawerWidth : 0)}px;
  }

  ${media.lessThan('tablet')} {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const ViewCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courseId = useNewViewCourseId();
  const urlPageId = useNewViewPageId();
  const course = useSelector(selectCourse);
  const isNotesOpen = useSelector(selectNotesSliderToggle);
  
  useEffect(() => {
    if (urlPageId === 'diploma') return;
    dispatch(fetchCourseById({ courseId })).then((res) => {
      const courseData = res.payload;
      if (!courseData?.toc) return;

      // Check if the entered pageId exists and its section is unlocked
      let foundPage = null;
      let sectionIsLocked = false;

      courseData.toc.forEach((section) => {
        section.pages.forEach((page) => {
          if (page.id.toString() === urlPageId) {
            foundPage = page;
            sectionIsLocked = section.isLocked;
          }
        });
      });

      // If the page exists and the section is unlocked, do nothing
      if (foundPage && !sectionIsLocked) {
        return;
      }

      // Check if there are any unlocked sections/pages
      const availableSections = courseData.toc.filter((section) => !section.isLocked);
      const firstAvailablePage = availableSections[0]?.pages.find((page) => !page.isLocked);

      if (!availableSections.length || !firstAvailablePage) {
        // If all pages are locked, navigate to course root
        navigate(`/view/course/${courseId}`, { replace: true });
        return;
      }

      // Redirect to the first available unlocked page
      const initialPage = courseData.lastViewedPageId || firstAvailablePage.id;
      navigate(`/view/course/${courseId}/${initialPage}`, { replace: true });
    });
  }, [courseId, urlPageId, dispatch, navigate]);

  const { prev, next } = usePageNavigation(course?.toc || [], urlPageId);

  const isPageLocked = (pageId) => {
    return course?.toc.some((section) => section.isLocked && section.pages.some((page) => page.id === pageId));
  };

  const goToPage = (pageId) => {
    if (!isPageLocked(pageId)) {
      navigate(`/view/course/${courseId}/${pageId}`, { replace: true });
    }
  };

  const handleOpenNotes = () => {
    dispatch(setNotesSliderToggle(!isNotesOpen));
  };

  const themeColor = useMemo(() => getUserThemeColor(course?.theme?.color), [course?.theme?.color]);// eslint-disable-line react-hooks/exhaustive-deps
  const lightColor = useMemo(() => getLighterThemeColor(themeColor), [themeColor]);
  const darkColor = useMemo(() => getDarkerThemeColor(themeColor), [themeColor]);

  const allSectionsLocked = useMemo(() => {
    return course?.toc?.every((section) => section.isLocked);
  }, [course]);

  const RenderNavButtons = React.memo(({ prev, next }) => {
    return (
      <NavButtonWrapper $compactSize={isNotesOpen} $backgroundColor={lightColor}>
        <Buttons.NavButton
          disabled={!prev || isPageLocked(prev.id)}
          onClick={() => goToPage(prev?.id)}
          $align={'left'}
          label={prev?.name || ''}
          startIcon={'ArrowLeft'}
          $with={'calc(50% - 22px)'}
          $hoverColor={darkColor}
          $bgcolor={lightColor}
        />
        <IconAsButtonStyled
          iconName={'EditNote'}
          tooltipLabel={'Your notes'}
          color={themeColor}
          clickHandle={handleOpenNotes}
        />
        <Buttons.NavButton
          disabled={!next || isPageLocked(next.id)}
          onClick={() => goToPage(next?.id)}
          $align={'right'}
          label={next?.name || ''}
          endIcon={'ArrowRight'}
          $with={'calc(50% - 22px)'}
          $hoverColor={darkColor}
          $bgcolor={lightColor}
        />
      </NavButtonWrapper>
    );
  });

  const isQuiz = useMemo(() => {
    return course?.settings?.quiz?.enabled && course?.settings?.quiz?.pages?.hasOwnProperty(urlPageId);
  }, [course, urlPageId]);

  return (
    <>
      <TocMenu />
      <ContentWrapper direction='column' $extraMargin={isNotesOpen}>
        {allSectionsLocked ? (
          <LockedSectionMessage>
            <h2>All course sections are locked</h2>
            <p>Please wait for content to be unlocked or contact the administrator.</p>
          </LockedSectionMessage>
        ) : (
          <>
            <CoursePage
              courseId={courseId}
              pageId={urlPageId}
              isQuiz={isQuiz}
              isNotesOpen={isNotesOpen}
              quizData={isQuiz ? course.settings.quiz.pages[urlPageId] : null}
            />
            <NotesMobile makeItOpen={isNotesOpen} setNotesDrawerOpen={handleOpenNotes} />
            <RenderNavButtons prev={prev} next={next} />
          </>
        )}
      </ContentWrapper>
      <NotesDrawer makeItOpen={isNotesOpen} setNotesDrawerOpen={handleOpenNotes} />
    </>
  );
};

export default ViewCourse;
