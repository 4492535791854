import React from 'react';
import styled from 'styled-components'
import { TextField } from '@mui/material';
import theme from '../theme';
import Typo from '../Typo';
import { Flex } from '..';

const TextFieldStyled = styled(TextField)`
  width: ${(props) => (props.width && props.width) || '100%'};
  max-width: ${(props) => props.$maxwidth};
  && p, input, textarea, label {
    font-family: ${theme.fonts.TEXT_FONT}, sans-serif !important;
    color: ${theme.colors.NEUTRAL_PRIMARY};
    font-weight: 300;
  }
  && input, textarea {
    line-height: 24px;
    font-size: ${theme.fontSizes.regular};
  }
  && label {
    font-size: ${theme.fontSizes.medium};
  }

  .MuiInputBase-root {
    background-color: white;
    padding-right: 8px;
    border-radius: ${({$pillShape})=> $pillShape && '20px'};
  }

  &.MuiFormControl-root {
    margin: ${({ flat }) => !flat && '0.5rem 0 0.5rem'};
  }
`

const Textinput = ({ onBlurValue, propName, isMultiline, innerRef, flatStyle, label, rows, pillShape, ...props }) => {
  const componentProps = {
    inputRef: innerRef,
    variant: 'outlined',
    size: 'small',
    multiline: isMultiline,
    $pillShape:pillShape,
    rows: rows || 4,
    onBlur: (el) => onBlurValue && onBlurValue(el),
    InputLabelProps: {
      shrink: true,
    },
    flat: flatStyle,
    inputProps: {
      ...props.InputProps,
      'data-propname': propName
    },
  }

  return (
    label ?
      <Flex direction='column' align='flex-start' width='100%' >
        <Typo.SmallText>{label}</Typo.SmallText>
        <TextFieldStyled {...componentProps} {...props} />
      </Flex> : <TextFieldStyled {...componentProps} {...props} />
  );
};

export default Textinput;
