import React, {useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import { Page, Loading, Flex, Typo } from '../../../../ui';
import { fetchPageById, markPageAsViewed, selectActiveSection, selectPage, selectPageStatus } from './coursePageSlice';
import { STATUS_SUCCEEDED, coursePageNavigation } from '../../../../utils/constants';
import { selectCourseTheme } from '../../../course/courseSlice';
import ModuleSwitcher from './ModuleSwitcher';
import Quiz from '../Quiz';
import { CONTENT_SMALL, media } from '../../../../utils/media';
import { getUserThemeColor } from '../../../../ui/theme/colors';
import { Breadcrumbs } from '@mui/material';

const RowModules = styled(Flex)` 
  ${media.lessThan('laptop')} {
    flex-direction:${({$isNotesOpen})=> $isNotesOpen && 'column'};
  }
  ${media.lessThan('bigTablet')} {
    flex-direction:column;
  }

  //hide empty components so we dont have extra empty space
  &:empty{
    display:none;
  }
`

const CoursePage = ({courseId, pageId, isQuiz, quizData, isNotesOpen}) => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(selectPage);
  const pageStatus = useSelector(selectPageStatus);
  const courseTheme = useSelector(selectCourseTheme);
  const activeSection = useSelector(selectActiveSection)
  const themeColor = useMemo(()=> getUserThemeColor(courseTheme?.color), [courseTheme]);

  useEffect(() => {
    if (pageId?.length && pageId !== 'diploma'){
      dispatch(fetchPageById({pageId})).then(() => {
        dispatch(markPageAsViewed({pageId, courseId}))
      });
    }
    
  }, [pageId, dispatch, courseId]);

  const renderPageContent = () => {
    return (
      <>
        {pageInfo.pageRows.map(pageRow =>
          <RowModules $isNotesOpen={isNotesOpen} gap="20" width="100%" key={pageRow.id} justify="center" align="flex-start">
            {pageRow.pageModules.map(module => <ModuleSwitcher columns={pageRow.pageModules.length} key={module.id} module={module} themeColor={themeColor} />)}
          </RowModules>
        )}
      </>
    );
  };

  const renderPageDiploma = () => {
    return (
      <>
        <RowModules $isNotesOpen={isNotesOpen} gap="20" width="100%" justify="center" align="normal">
          <ModuleSwitcher columns={1} key={'diploma'} module={{data:{}, type:{name:'diploma'}}} themeColor={themeColor} />
        </RowModules>
      </>
    );
  };

  const breadcrumbs = useMemo(()=>{
    return [
      <Typo.MediumText>{activeSection?.name}</Typo.MediumText>,
      <Typo.MediumText>{pageInfo?.name}</Typo.MediumText>,
      // <Typo.MediumText> currentPage / totalPages</Typo.MediumText>,
    ];
  }, [activeSection, pageInfo])

  return (
    <Page align='flex-start' substractBottom={coursePageNavigation} gap="40" size={CONTENT_SMALL}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      {pageId === 'diploma' ? renderPageDiploma() : pageStatus === STATUS_SUCCEEDED && !isEmpty(pageInfo) ? (
        isQuiz ? <Quiz quizData={quizData} pageInfo={pageInfo} themeColor={themeColor} /> 
          : renderPageContent()
      ) : <Loading themeColor={themeColor}/>}
    </Page>
  )
};

export default CoursePage;
